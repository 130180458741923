import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {ProductItem} from '../../model/productItem';
import {Product} from '../../model/product';
import {environment} from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { ProductFamily } from '../../model/productfamily';
import { KeyValueTypedPair } from '../../model/productOrFamilyName';
import { FeatureSettings } from '../../model/config/FeatureSettings';
import { ProductsParameters } from '@src/model/filter-parameters/products-parameters';
import * as moment from 'moment';
@Injectable()
export class ProductsService extends BaseService {
  private productItemsBaseUrl = environment.apiBaseUrl + '/api/productitems';
  private productBaseUrl = environment.apiBaseUrl + '/api/products';
  private productFamiliesBaseUrl = environment.apiBaseUrl + '/api/productfamilies';

  constructor(injector: Injector) {
    super(injector);
  }

  getProductById(id: number): Observable<Product> {
    const url = `${this.productBaseUrl}/${id}`;
    return this.get<Product>(url);
  }

  getProductItemById(id: number): Observable<ProductItem> {
    const url = `${this.productItemsBaseUrl}/${id}`;
    return this.get<ProductItem>(url);
  }

  getProductItemsProductFamilyBySN(sn: string): Observable<ProductItem> {
    const url = `${this.productItemsBaseUrl}/filtersFamilyProduct/serialNumber?` + this.buildQueryParameters('serialNumber', sn);
    return this.get<ProductItem>(url);
  }

  getProductItemsBySN(sn: string): Observable<ProductItem[]> {

    const url = `${this.productItemsBaseUrl}/byserialnumber?` + this.buildQueryParameters('serialNumber', sn);
    return this.get<ProductItem[]>(url);
  }

  getProductBySKU(sku: string, isSerializedItem: boolean): Observable<Product[]> {
   const url = `${this.productBaseUrl}?` + `${this.buildQueryParameters('sku', sku )}&`+ `isSerializedItem=${isSerializedItem}`;
    return this.get<Product[]>(url);
  }

  getFreeLimitedEditionUnitNumbers(sku: string): Observable<number[]> {
   const url = `${this.productBaseUrl}/freeLimitedEditionUnitNumbers?` + `${this.buildQueryParameters('sku', sku )}`;
    return this.get<number[]>(url);
  }

  getLimitedEditionProductItem(sku: string, limitedEditionUnitNumber: number ): Observable<ProductItem> {
    const url = `${this.productBaseUrl}/limitedEditionProductItem?` 
      + `${this.buildQueryParameters('sku', sku )}&`
      + `${this.buildQueryParameters('limitedEditionUnitNumber', limitedEditionUnitNumber )}`;
    return this.get<ProductItem>(url);
  }

  getAllProductFamilies(): Observable<ProductFamily[]> {
    return this.get<ProductFamily[]>(this.productFamiliesBaseUrl);
  }
  
  getProductsNames(q: string, withFamilyName: boolean = true): Observable<KeyValueTypedPair[]>{
    if (withFamilyName) {
      return this.get<KeyValueTypedPair[]>(`${this.productBaseUrl}/filters?q=${q}&_select=familyname&_select=sku`);
    } else {
      return this.get<KeyValueTypedPair[]>(`${this.productBaseUrl}/filters?q=${q}&_select=sku`);
    }
  }
  getProductsNamesByModel(q: string, withFamilyName: boolean = true): Observable<KeyValueTypedPair[]>{
    if (withFamilyName) {
      return this.get<KeyValueTypedPair[]>(`${this.productBaseUrl}/filters?q=${q}&_select=familyname&_select=model`);
    } else {
      return this.get<KeyValueTypedPair[]>(`${this.productBaseUrl}/filters?q=${q}&_select=model`);
    }
  }
  
  getProductSKUs(q: string): Observable<KeyValueTypedPair[]>{
    return this.get<KeyValueTypedPair[]>(`${this.productBaseUrl}/filters?q=${q}&_select=sku`);
  }

  getImageBySku(sku: string){
    const url =`${this.productBaseUrl}/image?sku=${sku}`;
    return this.get<ArrayBuffer>(url);
  }

  getProductSerialNumbers(q: string): Observable<KeyValueTypedPair[]>{
    return this.get<KeyValueTypedPair[]>(`${this.productItemsBaseUrl}/filters/serialNumber?q=${q}&_select=serialNumber`);
  }

  filterProducts(productsParameters: ProductsParameters, sort: string, pageSize: number, page: number): Observable<any> {
    productsParameters.isUsed = false;
    let url = `${this.productItemsBaseUrl}?` ;
    if(productsParameters.serialNumber) url += `${this.buildQueryParameters('serialNumber', productsParameters.serialNumber, )}&` ; 
    if(productsParameters.productSku)   url += `${this.buildQueryParameters('productSku', productsParameters.productSku, )}&` ;
    if(productsParameters.yesCPO)       url += `${this.buildQueryParameters('yesCPO', productsParameters.yesCPO, )}&` ;
    if(productsParameters.noCPO)        url += `${this.buildQueryParameters('noCPO', productsParameters.noCPO, )}&` ;
    url +=  `${this.buildQueryParameters('from', productsParameters.from == null ? null : moment(productsParameters.from).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('to', productsParameters.to == null ? null : moment(productsParameters.to).format('YYYY-MM-DD'))}&` +
      'isUsed=' + productsParameters.isUsed + '&' +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`;
      return this.getWithHeader<ProductItem[]>(url);
  }

  createProductItem(sn: string, sku: string): Observable<ProductItem> {
    const url = `${this.productItemsBaseUrl}?serialNumber=${sn}&productSku=${sku}`;
    return this.post<ProductItem>(url, null);
  }

  updateProductItem(id: number, isCpo: boolean, warrantyDurationMonths?: number): Observable<ProductItem> {
    if (warrantyDurationMonths == null)
    {
      const url = `${this.productItemsBaseUrl}?id=${id}&isCpo=${isCpo}`;
      return this.put<ProductItem>(url, null);
    }
    else
    {
      const url = `${this.productItemsBaseUrl}?id=${id}&isCpo=${isCpo}&warrantyDurationMonths=${warrantyDurationMonths}`;
      return this.put<ProductItem>(url, null);
    }
  }
  
  getProductItemCpoSetting(): Observable<FeatureSettings> {
    return this.get<FeatureSettings>(`${this.productBaseUrl}/ProductItemCpoSetting`);
  }
  getCreateProductItemSetting(): Observable<FeatureSettings> {
    return this.get<FeatureSettings>(`${this.productBaseUrl}/CreateProductItemSetting`);
  }
}
